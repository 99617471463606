import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const WebAppPage = () => (
  <div>
      <SEO 
        title="Web Applications" 
        description="Completely free Web Applications built into your Web Control Panel. Utilizing our Web Applications, you’ll be able to concentrate on your website’s popularity 
        directly from your personal Web Control Panel."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"> <span className="block text-indigo-600 xl:inline">Web Applications</span>  <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">Completely free Web Applications built into your Web Control Panel</p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/marketing-tools-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> Utilizing our Web Applications, you’ll be able to concentrate on your website’s popularity 
                 directly from your personal Web Control Panel. Through the Sitemap Generator you will get complete sitemap for your site within a few 
                 minutes. You can send the sitemap to major search engines to ensure that they can index your web site as quickly as possible.
                 <br/><br/>
                 <span role="img" aria-label="emoji">✨ </span> At the same time, from the RSS News tool, you could install regularly kept up to date 
                 information on your website, which is actually a promise for higher ranking positions with major search engines. Our GeoIP 
                 re–direction tool will help you reroute visitors coming from a specified region into a specialized language version of 
                 your website for more precise marketing end results.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/a-sitemap-generator.webp" className="w-60" alt="A Sitemap Generator" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A Sitemap Generator</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    A sitemap application incorporated into your Web Control Panel<br/><br/>
                                    If you have just recently launched your web site and need it to be scanned faster from the the major search engines, 
                                    or if you need to give your visitors a simple way to browse through all of your web pages, you’ll need to utilize a 
                                    sitemap. Sitemap is a a listing of all the webpages on your website that are linked to. In most cases, you need to 
                                    use 3rd party tools to get the job done, but with Sheephostingbay, you can find a Sitemap Generator built right 
                                    into the Web Control Panel.
                                    <br/><br/>
                                    The Sitemap Generator Sheephostingbay offers is generally user friendly and because it’s absolutely compatible with 
                                    our cloud website hosting platform – your web site is going to be scanned very quickly.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">GeoIP Redirection</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Direct site visitors according to their location<br/><br/>
                                    Using the GeoIP re–direction tool that’s built into the Domain Names Manager of the Sheephostingbay Web Control Panel, 
                                    you are able to re–route your clients based on their geographic location. For example, in case you have a number of 
                                    language variants of your site, you can set up a specific language variation to instantly open for users from the 
                                    country where that language is used. This way, in case a user coming from France opens up your web site, she will 
                                    be directly taken to the French version, without needing to move to French on her own. To use the GeoIP 
                                    re–direction tool , you don’t need just about any knowledge or tech capabilities. Just select the 
                                    geographical area of your respective visitors and the link to your web site that you’d like to 
                                    come up for them automatically.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/geo-ip.webp" className="w-60" alt="GeoIP Redirection" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/rss-news.webp" className="w-60" alt="RSS News" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">RSS News</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Show the most current publications within your website<br/><br/>
                                    What exactly is RSS? RSS can be described as a technology for distributing and gathering information. It’s becoming 
                                    frequently used by media sites, individual blogs, bulletins, and so forth. The posted content is readily gathered 
                                    from a news aggregator and then presented to the consumer. Working with RSS, end users can obtain news from 
                                    different sites and review them in a single location.
                                    <br/><br/>
                                    Using the RSS News Publication tool, you can easily add headlines feeds coming from a lot of the world’s most common 
                                    publication sites and showcase them within your site.
                                    </div>
                                </div>
                            </div>
                        </div>   

                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default WebAppPage
